"use client";

import React, { useEffect } from "react";
import { signOut, useSession } from "next-auth/react";
import { useRouter } from "next/navigation";
import Cookies from "js-cookie";
import { Layout } from "@components/Layout/index";
import { Render } from "@components/sections";
import { trackEvent } from "@lib/track-event";

export default function ClientComponent({
  config,
  preview,
  title = "Home",
  content,
  modal,
  allProducts,
  locale,
}) {
  const { data: session } = useSession();
  const router = useRouter();
  const isFrench = locale === "fr";

  useEffect(() => {
    trackEvent("view_page", {
      pageTitle: title,
      pageCategory: "Home Page",
      pageLocation: window.location.href,
      pagePath: window.location.pathname,
    });
  }, [title]);

  useEffect(() => {
    const hasSignout = new URLSearchParams(window.location.search).has("signout");
    async function logout() {
      const data = await signOut({ redirect: false, callbackUrl: "/" });
      Cookies.remove("kotn_logged_in");
      router.push(data.url);
    }
    if (hasSignout && session) {
      logout();
    }
  }, [session]);

  if (!content || !allProducts || allProducts.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <Layout config={config} title={title} isPreview={preview} modal={modal} pageFound={true}>
      <Render
        sections={isFrench ? content.filter((block) => block._type !== "editorialList") : content}
        allProducts={allProducts}
      />
    </Layout>
  );
}
